<template>
<svg width="953" height="170" viewBox="0 0 953 170" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.3353 129.636C23.3353 156.277 36.1936 169.597 62.1482 169.597C74.2921 169.597 83.5786 167.456 90.4839 163.175V135.345C84.7691 138.199 79.0544 139.626 73.1015 139.626C61.6719 139.626 56.9096 134.631 56.9096 122.976V75.8786H90.722V47.8106H56.9096V15.2233H23.3353V47.8106H0V75.8786H23.3353V129.636Z" fill="black"/>
<path d="M152.491 166.505V47.8106H118.916V166.505H152.491Z" fill="black"/>
<path d="M243.682 169.597C257.73 169.597 270.112 166.743 281.304 160.796V132.252C272.017 137.723 261.302 140.34 249.158 140.34C238.919 140.34 230.109 137.247 222.965 131.063C215.822 124.879 212.25 116.791 212.25 107.039C212.25 97.2863 215.822 89.1989 222.727 83.2523C229.871 77.0679 238.681 73.9756 249.158 73.9756C258.921 73.9756 269.16 76.3543 279.399 81.1116V52.5679C268.922 47.3349 257.254 44.7184 244.158 44.7184C226.299 44.7184 211.06 50.4271 198.201 61.8446C185.581 73.0242 179.152 88.0096 179.152 106.801C179.152 125.592 185.581 140.815 198.201 152.471C210.822 163.888 226.061 169.597 243.682 169.597Z" fill="black"/>
<path d="M343.363 166.505V115.364L390.271 166.505H433.609L373.841 101.33L425.513 47.8106H384.319L343.363 91.1018V0H309.788V166.505H343.363Z" fill="black"/>
<path d="M501.829 169.597C521.354 169.597 536.355 165.553 547.071 157.466V130.587C534.927 137.485 522.307 141.053 508.972 141.053C488.018 141.053 472.778 132.49 469.207 116.315H554.452V107.514C554.452 89.9125 549.214 75.165 538.975 63.0339C528.736 50.9029 515.163 44.7184 497.781 44.7184C479.446 44.7184 464.444 50.665 453.015 62.796C441.585 74.6892 435.871 89.4368 435.871 107.039C435.871 125.83 442.3 141.053 454.92 152.471C467.54 163.888 483.256 169.597 501.829 169.597ZM469.207 94.9077C470.874 80.8737 481.113 71.1213 496.352 71.1213C511.353 71.1213 520.64 81.8251 521.354 94.9077H469.207Z" fill="black"/>
<path d="M591.418 129.636C591.418 156.277 604.276 169.597 630.231 169.597C642.374 169.597 651.661 167.456 658.566 163.175V135.345C652.852 138.199 647.137 139.626 641.184 139.626C629.754 139.626 624.992 134.631 624.992 122.976V75.8786H658.804V47.8106H624.992V15.2233H591.418V47.8106H568.082V75.8786H591.418V129.636Z" fill="black"/>
<path d="M152.491 0V37.1534H135.704H118.917V0H152.491Z" fill="black"/>
<path d="M899.901 169.595C919.426 169.595 934.427 165.551 945.143 157.464V130.585C932.999 137.483 920.379 141.051 907.044 141.051C886.09 141.051 870.851 132.488 867.279 116.314H952.524V107.513C952.524 89.9107 947.286 75.1631 937.047 63.032C926.808 50.901 913.235 44.7165 895.853 44.7165C877.518 44.7165 862.517 50.6631 851.087 62.7942C839.657 74.6874 833.943 89.4349 833.943 107.037C833.943 125.828 840.372 141.051 852.992 152.469C865.612 163.886 881.328 169.595 899.901 169.595ZM867.279 94.9058C868.946 80.8718 879.185 71.1194 894.424 71.1194C909.425 71.1194 918.712 81.8233 919.426 94.9058H867.279Z" fill="#0575DD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M711.661 47.8094L681.655 47.8094V47.8096H681.652V166.504H715.227V102.996C724.903 109.641 737.126 113.608 750.415 113.608C765.45 113.608 779.12 108.531 789.281 100.242V166.504H822.856V75.9162H822.859V47.8094L789.171 47.8094C787.656 67.8891 770.884 83.7101 750.416 83.7101C729.949 83.7101 713.176 67.8891 711.661 47.8094Z" fill="#0575DD"/>
</svg>
</template>
